/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/en/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prohlížet"}>
        <SiteHeader />

        <Column className="js-anim  --anim3 --anim-s4 pb--40 pt--80" anim={"3"} name={"0il8bnfu5zat"} animS={"4"}>
          
          <ColumnWrap className="column__flex --center el--1 mt--60" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 w--400" style={{"maxWidth":1110}} content={"Choose the one for you"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80" name={"8jss9bj1yde"} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3 pb--40" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/8b3133abda5c4a6a8da98b83bbed34d3_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/8b3133abda5c4a6a8da98b83bbed34d3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/8b3133abda5c4a6a8da98b83bbed34d3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/8b3133abda5c4a6a8da98b83bbed34d3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/8b3133abda5c4a6a8da98b83bbed34d3_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/85cd41a3e947489197bed1797503363f_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/85cd41a3e947489197bed1797503363f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/85cd41a3e947489197bed1797503363f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/85cd41a3e947489197bed1797503363f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/85cd41a3e947489197bed1797503363f_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/68f0deb0abd4494ab8793d09c4064b96_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/68f0deb0abd4494ab8793d09c4064b96_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/68f0deb0abd4494ab8793d09c4064b96_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/68f0deb0abd4494ab8793d09c4064b96_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/68f0deb0abd4494ab8793d09c4064b96_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/3ccad1f19f4a40238adf9a9783d1d57a_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/4ce07fbeaa68484bbc5c7969045306b3_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2 --anim-s4 mt--60" anim={"2"} animS={"4"}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=860x_.jpg"} svg={false} ratio={"3:4"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=860x_.jpg 860w, https://cdn.swbpg.com/t/11212/857f2e9c255a4126be25d0333130c87f_s=1400x_.jpg 1400w"} content={null} position={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Monstera — 27cm"}>
              </Subtitle>

              <Text className="text-box fs--22 mt--06" content={"$ 17"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 btn-box--filling2 ff--1" href={"/en/item"} content={"More"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--80" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" style={{"maxWidth":1400}} columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box" content={"<span style='color: var(--color-dominant)'>Flora<br>722 Finch Street</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/cd8db1a198b540c896a32b45d514eef3.svg"} svg={false} sizes={"100vw"} style={{"maxWidth":64}} srcSet={""} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--right" content={"<span style='color: var(--color-dominant)'>510-851-40X4<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}